import * as React from "react"
import BlogLayout from "../components/word/BlogLayout"
import WordList from "../components/word/WordList"
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react"
import { ChevronRightIcon } from "@chakra-ui/icons"

export default function CategoryTemplate({ pageContext }: any): JSX.Element {
  const { category, members } = pageContext
  return (
    <BlogLayout pageTitle={`category: ${category}`}>
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        pt={6}
        pl={4}
        pb={1}
      >
        <BreadcrumbItem>
          <BreadcrumbLink href="/word">word</BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink href="/word/category">category</BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href="#">{category}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <WordList nodes={members.data.allMdx.nodes} />
    </BlogLayout>
  )
}
